import React, { useEffect } from "react";
import "../style/App.css";
import "../style/Portfolio.css";
import { motion } from "framer-motion";
import Footer from "./Footer";
import Navbar from "./Navbar";
import VideoCard from "./VideoCard";

export default function Portfolio() {
  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add("show");
        }
      });
    });
    const hiddenElements = document.querySelectorAll(".hidden-text, .hidden");
    hiddenElements.forEach((el) => {
      observer.observe(el);
    });
  }, []);

  return (
    <motion.div
      initial={{ opacity: 0.7 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.35, ease: "easeOut" }}
    >
      <Navbar />
      <div className="portfolio" id="top">
        <h1 className="hidden-text">Portfolio</h1>
      </div>
      <div className="portfolio-gallery">
        <h2 className="hidden-text section-title">Studio festiwalowe MDAG</h2>
        <div className="portfolio-gallery-container">
          <VideoCard
            url="https://youtu.be/M39Vvp9pSjY?si=CXn_H_Lj4lGBJV0S"
            title="Czy ryby potrafią śnić?"
          />
          <VideoCard
            url="https://youtu.be/auPp-rN0Dh8?si=D4B0ieWexxVixsWq"
            title="DJ Wika promuje piękną starość!"
          />
          <VideoCard
            url="https://youtu.be/Biv_ALFYMFg?si=YmHoYUiX0mqzNVpy"
            title="Czy nago jesteśmy bardziej szczerzy_e?"
          />
          <VideoCard
            url="https://youtu.be/Lv8ukbqZucY?si=qgtNDNRcttXISDl1"
            title="Czy miesiączka i zdrowie psychiczne to dobre tematy na film?"
          />
        </div>

        <h2 className="hidden-text section-title">Materiały edukacyjne</h2>
        <div className="portfolio-gallery-container">
          <VideoCard
            url="https://youtu.be/oYi09VK4SKY?feature=shared"
            title="Zapowiedź filmu „Comedy Queen” | 9. MFF Kino Dzieci | Konkurs filmowych odkryć"
          />
          <VideoCard
            url="https://youtu.be/QlwPvOVPkl4?si=zT2P2Dc2-qxQzvxo"
            title="Blisko | Nowe Horyzonty"
          />
          <VideoCard
            url="https://youtu.be/qKSjYqfSD3E?si=qGJ_sUVAJ_JGw3fm"
            title="Smok Diplodok - prelekcja i rozmowa z Wojtkiem Wawszczykiem"
          />
          <VideoCard
            url="https://youtu.be/je9Yz7ejKow?si=hzO5r2OqQRLR42QX"
            title="Milczenie Julie - materiał edukacyjny"
          />
        </div>

        <h2 className="hidden-text section-title">Wywiady</h2>
        <div className="portfolio-gallery-container">
          <VideoCard
            url="https://youtu.be/0cee_a6pvBc?si=3F2y4RW3uxazdBvl"
            title="Wyrwa - Rozmowa z Wojciechem Chmielarzem i Grzegorzem Damięckim"
          />
          <VideoCard
            url="https://youtu.be/qPVTWJKQUpc"
            title="Ambasada Szwecji"
          />
        </div>

        <h2 className="hidden-text section-title">Social media</h2>
        <div className="portfolio-gallery-container">
          <VideoCard
            url="https://youtube.com/shorts/I5DxGZti3aU?si=bIjjssqLaPH51wjz"
            title="Brutalist Poland"
            nothumb
          />
          <VideoCard
            url="https://youtube.com/shorts/z5gCDFcnhdo?si=aZyu-Gcc6HH-fLYz"
            title="Diwa. Opowieść o Marii Callas"
            nothumb
          />
          <VideoCard
            url="https://youtube.com/shorts/DZvn_gvKSbM?si=ATqe5hpvEbth63Lt"
            title="Stąd w nieznane"
            nothumb
          />
        </div>

        <h2 className="hidden-text section-title">Reklamy</h2>
        <div className="portfolio-gallery-container">
          <VideoCard
            url="https://vimeo.com/273678411"
            title="Fundacja Orange"
          />
          <VideoCard
            url="https://vimeo.com/391186649"
            title="Bestseller Empiku"
          />
          <VideoCard url="https://vimeo.com/431586852" title="Pigment" />
        </div>
      </div>
      <Footer />
    </motion.div>
  );
}
