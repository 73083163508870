import React from "react";
import "../style/Footer.css";
import { HashLink as Link } from "react-router-hash-link";

export default function Footer() {
  return (
    <div className="footer">
      <div className="footer-container">
        <div className="footer-links">
          <Link to="/#top">Strona główna</Link>
          <Link to="/o-nas#top">O nas</Link>
          <Link to="/portfolio#top">Portfolio</Link>
          <Link to="kontakt#top">Kontakt</Link>
        </div>
        <div className="footer-social">
          <div className="footer-social-wrapper">
            <a
              href="https://facebook.com/kajutex"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="fa-brands fa-facebook"></i>
            </a>
            <a
              href="https://instagram.com/kajutex"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="fa-brands fa-square-instagram"></i>
            </a>
            <a
              href="https://tiktok.com/@kajutex.pl"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="fa-brands fa-tiktok"></i>
            </a>
          </div>
        </div>
        <div className="footer-img">
          <img
            className="masterclassfilm-logo"
            src={require("../assets/masterclassfilm_logo.png")}
            alt="MasterClass Film"
          />
          <img
            className="kajutex-logo"
            src={require("../assets/kajutex_logo.png")}
            alt="MasterClass Film"
          />
        </div>
      </div>
      <div className="footer-bottom">
        <div className="footer-bottom-container">
          <p>projekt i wykonanie strony www:</p>
          <a
            href="https://polyphoniccode.pl"
            target="_blank"
            rel="noopener noreferrer"
            title="przejdź do strony PolyphonicCode"
          >
            PolyphonicCode
          </a>
        </div>
      </div>
    </div>
  );
}
