import React from "react";
import "../style/App.css";
import Hero from "./Hero";
import Partners from "./Partners";
import Intro from "./Intro";
import Contact from "./Contact";
import { motion } from "framer-motion";
import Footer from "./Footer";
import Navbar from "./Navbar";
import Videos from "./Videos";

export default function Home() {
  return (
    <motion.div
      initial={{ opacity: 0.7 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.35, ease: "easeOut" }}
    >
      <Navbar />
      <Hero />
      <Intro />
      <Videos />
      <Partners />
      <Contact />
      <Footer />
    </motion.div>
  );
}
