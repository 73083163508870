import React, { useEffect, useState } from "react";
import "../style/App.css";
import "../style/Onas.css";
import { motion } from "framer-motion";
import Footer from "./Footer";
import Navbar from "./Navbar";
import Popup from "reactjs-popup";
import "../style/popup.css";

export default function Onas() {
  const [kajaClicked, setKajaClicked] = useState(false);
  const [michalClicked, setMichalClicked] = useState(false);
  const [mateuszClicked, setMateuszClicked] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add("show");
        }
      });
    });
    const hiddenElements = document.querySelectorAll(
      ".hidden-text, .hidden, .hidden-images"
    );
    hiddenElements.forEach((el) => {
      observer.observe(el);
    });
  }, []);

  const kajaClickHandler = () => {
    setKajaClicked(!kajaClicked);
  };
  const michalClickHandler = () => {
    setMichalClicked(!michalClicked);
  };
  const mateuszClickHandler = () => {
    setMateuszClicked(!mateuszClicked);
  };

  return (
    <motion.div
      initial={{ opacity: 0.7 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.35, ease: "easeOut" }}
    >
      <Navbar />
      <div className="o-nas" id="top">
        <h1 className="hidden-text">O nas</h1>
      </div>

      <div className="o-nas-section">
        <div className="top-container">
          <p className="hidden o-nas--main-text cool-border-2">
            Oto nasza drużyna: ciekawi świata, dynamiczni i&nbsp;zawsze
            z&nbsp;ręką na pulsie. Cały czas się uczymy i&nbsp;rozwijamy,
            zbieramy nowe doświadczenia i&nbsp;obserwujemy świat i&nbsp;media.
            Wszystko po to, żeby tworzyć fajne materiały, które angażują
            odbiorców. Celem zawsze jest przede wszystkim zdrowy kontent. Mamy
            też misję! To hasło „ZAWSZE Z&nbsp;MŁODZIEŻĄ”, więc chętnie
            zapraszamy do współpracy młodych twórców.
          </p>
        </div>
        <div className="o-nas-section--item">
          <img
            src={require("../assets/kaja.jpg")}
            alt=""
            className="hidden o-nas--img kaja"
            onClick={kajaClickHandler}
          />
          <button className="hidden o-nas--h2 name" onClick={kajaClickHandler}>
            Kaja Klimek
          </button>
          {kajaClicked && (
            <Popup
              modal
              nested
              open={kajaClicked}
              closeOnDocumentClick
              onClose={kajaClickHandler}
            >
              {() => (
                <div className="modal">
                  <div className="header"> Kaja Klimek </div>
                  <div className="content">
                    <p className="o-nas--p" onClick={kajaClickHandler}>
                      Doktorka kulturoznawstwa, edukatorka i&nbsp;krytyczka
                      filmowa i&nbsp;popkulturalna. Nieustannie dyskutuje
                      o&nbsp;filmach i&nbsp;serialach – z&nbsp;widzami, gdy
                      tylko nadarzy się okazja, z&nbsp;twórcami na festiwalach,
                      z&nbsp;innymi krytykami w&nbsp;mediach, ze studentami na
                      Uniwersytetach Warszawskim i&nbsp;SWPS... i&nbsp;co
                      najważniejsze: z&nbsp;dzieciakami i&nbsp;młodzieżą
                      w&nbsp;ramach programów edukacji filmowej. Współtworzyła
                      programy filmowe w&nbsp;TVP i&nbsp;TVN Fabuła, prowadzi
                      program Warsztat Filmowy w&nbsp;Red Carpet TV. Laureatka
                      nagrody PISF w&nbsp;kategorii krytyka filmowa (2024).
                      W&nbsp;internecie działa jako Kajutex (YT, FB, IG, TT -
                      jako kajutex.pl).
                    </p>
                  </div>
                </div>
              )}
            </Popup>
          )}
        </div>
        <div className="o-nas-section--item">
          <img
            src={require("../assets/michal.jpg")}
            alt=""
            className="hidden o-nas--img michal"
            onClick={michalClickHandler}
          />
          <button
            className="hidden o-nas--h2 name"
            onClick={michalClickHandler}
          >
            Michał Ciechomski
          </button>
          {michalClicked && (
            <Popup
              modal
              nested
              open={michalClicked}
              closeOnDocumentClick
              onClose={michalClickHandler}
            >
              {() => (
                <div className="modal">
                  <div className="header"> Michał Ciechomski </div>
                  <div className="content">
                    <p className="o-nas--p" onClick={michalClickHandler}>
                      Absolwent reżyserii filmowej na Akademii Krzysztofa
                      Kieślowskiego w Katowicach oraz Lotnictwa
                      i&nbsp;Kosmonautyki na&nbsp;Politechnice Warszawskiej.
                      Wielokrotnie nagradzany i&nbsp;nominowany na największych
                      polskich i&nbsp;zagranicznych festiwalach, takich jak:
                      EnergaCamerImage, Warszawski Festiwal Filmowy, Krakowski
                      Festiwal Filmowy, Młodzi i&nbsp;Film w Koszalinie, Moscow
                      Shorts czy Festiwal Filmowy w Gdyni. Filmy „Miłość”
                      i&nbsp;"Uległość" trafiły pod opiekę Krakowskiej Fundacji
                      Filmowej w&nbsp;2020 roku, dzięki czemu zyskały szansę na
                      szerszą zagraniczną promocję. Obecnie pracuje nad swoim
                      debiutanckim filmem pełnometrażowym.
                    </p>
                  </div>
                </div>
              )}
            </Popup>
          )}
        </div>
        <div className="o-nas-section--item">
          <img
            src={require("../assets/mateusz.jpg")}
            alt=""
            className="hidden o-nas--img mateusz"
            onClick={mateuszClickHandler}
          />
          <button
            className="hidden o-nas--h2 name"
            onClick={mateuszClickHandler}
          >
            Mateusz Mirocha
          </button>
          {mateuszClicked && (
            <Popup
              modal
              nested
              open={mateuszClicked}
              closeOnDocumentClick
              onClose={mateuszClickHandler}
            >
              {() => (
                <div className="modal">
                  <div className="header"> Mateusz Mirocha </div>
                  <div className="content">
                    <p className="o-nas--p" onClick={mateuszClickHandler}>
                      Absolwent kierunku operatorskiego w Szkole Filmowej im.
                      Krzysztofa Kieślowskiego w Katowicach. Autor zdjęć do
                      krótkometrażowych filmów fabularnych i&nbsp;dokumentalnych
                      pokazywanych na festiwalach filmowych. Laureat konkursu
                      reklamowego Papaya Young Directors 2019. Studiował pod
                      opieką wybitnych operatorów i&nbsp;reżyserów: Marcina
                      Koszałki, Adama Sikory, Bogdana Dziworskiego, Jacka
                      Bławuta, Xawerego Żuławskiego. Nie przestaje
                      eksperymentować z&nbsp;obrazem, a&nbsp;każdy projekt
                      traktuje jak nowe wyzwanie, do którego podchodzi
                      z&nbsp;mnóstwem energii i&nbsp;z&nbsp;głową pełną świeżych
                      pomysłów.
                    </p>
                  </div>
                </div>
              )}
            </Popup>
          )}
        </div>
      </div>
      <Footer />
    </motion.div>
  );
}
