import ReactPlayer from "react-player";

export default function VideoCard({url, title, nothumb}) {
  return (
    <div className="video-wrapper">
      <div className="video-card hidden">
        <div className="player-wrapper">
          <ReactPlayer
            url={url}
            controls
            className="react-player"
            playsInline
            width="100%"
            height="100%"
            light={nothumb ? false : true}
          />
        </div>
      </div>
      <div className="nametag">
        <h2>{title}</h2>
      </div>
    </div>
  );
}
